.navbar {
  background-color: rgb(61, 61, 61);
  height: 80px;
  /* background-color: #287cfc; */
}

.title {
  color: white;
  font-weight: 300;
  font-size: x-large;
}

.omega {
  color: red;
  font-weight: 900;
  font-size: xx-large;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.logo {
  height: 50px;
  width: 50px;
  align-self: baseline;
}

.navitem {
  color: white;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 5px;
  padding: 4px;
  transition: 0.6s;
}

.navitem:hover {
  background-color: rgba(50, 163, 255, 0.884);
  color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.349);
  translate: 0 -8px;
}
